import React from 'react';
import Contact from '../Home/contact/Contact'; // Assurez-vous que le chemin vers Contact.js est correct
import './ContactPage.css';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  return (
    <div className='contact-page'>
      <Helmet>
        <title> Contact | Webmaster & Développement Web - Maissa Dev</title>
      </Helmet>
      {/* <h1>Page de Contact</h1> */}
      <Contact />
    </div>
  );
};

export default ContactPage;
