// Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import './NavBar2.css'; // Assurez-vous de créer ce fichier pour les styles
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null); // Référence pour la navbar

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');

      if (window.scrollY > 50) {
        navbar.classList.add('shrink');
      } else {
        navbar.classList.remove('shrink');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Gestionnaire de clic pour fermer la navbar lorsqu'on clique en dehors
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Ajouter l'événement de clic au document
    document.addEventListener('mousedown', handleClickOutside);

    // Nettoyer l'événement pour éviter les fuites de mémoire
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Empêcher le défilement lorsque le menu est ouvert
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Nettoyage pour rétablir le défilement par défaut
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  // Fonction pour fermer le menu quand un lien est cliqué
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar" ref={navRef}>
      <div className="logo">
        <a href="/">Maissa Dev</a>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li>
          <a href="/">Accueil</a>
        </li>
        <li>
          <Link to="/projets" onClick={handleLinkClick}>
            Réalisations
          </Link>
        </li>
        <li>
          <Link to="/avis" onClick={handleLinkClick}>
            Avis
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={handleLinkClick}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
