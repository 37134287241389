import React, { useState } from 'react';
import "./Recontact.css";
import { leapfrog } from 'ldrs'


const Recontact = () => {

  leapfrog.register()
  
  const [buttonText, setButtonText] = useState("Être recontacté");
  const [info, setInfo] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (event) => {
    setInfo(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVisible(true);
    setIsSubmitting(true);
    console.log(JSON.stringify(info));
    const formData = {
      info: info
    };
    try {
      setButtonText('');
      const response = await fetch('http://mails.maissadev.com/recontact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });
  
      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData.message);
      } else {
        console.error(responseData.message);
      }
      setTimeout(() => {
        setIsVisible(false);
        setInfo('');
        setButtonText("Envoyé !");
      }, 3000);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la requête :', error);
      setTimeout(() => {
        setIsVisible(false);
        setInfo('');
        setButtonText("Erreur, réessayez.");
      }, 3000);
    } finally {
     
      setTimeout(() => {
        setIsSubmitting(false);
        setButtonText("Être recontacté");
      }, 5000);
    }
  };


  return (
    <div className='recontact-container'>
      <h2 className='recontact-title'>Vous pouvez aussi être recontacter </h2>
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="text"
            value={info}
            onChange={handleInputChange}
            placeholder="E-mail ou numéro de téléphone"
            required
            className='recontact-input'
          />
        </label>
        <button type="submit" className={isSubmitting ? 'recontact-button submitting' : 'recontact-button'} disabled={isSubmitting} >
            {buttonText}

<div className='spinner'>
{isVisible ? (
  <div className="visibleClass">
<l-leapfrog
  size="30"
  speed="2.5" 
  color="#FEFBFE"
></l-leapfrog>
</div>) : null}
</div>
          </button>
      </form>
    </div>
  );
};

export default Recontact;
