import React from 'react';
import './PagesLegales.css';
import { Helmet } from 'react-helmet';

const PolitiqueConfidentialite = () => {
  return (
    <div className="pages-legales-container">
       <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h2 className='pages-legales-title'>Politique de Confidentialité</h2>

      <div className="pages-legales-content">
        <h4>Collecte et utilisation des informations personnelles</h4>
        <p>
          Lorsque vous choisissez de nous fournir votre adresse e-mail ou votre numéro de téléphone pour être contacté, recevoir un e-book ou être recontacté, nous recueillons ces informations dans le but de répondre à votre demande et de vous fournir le service demandé. Ces informations seront utilisées uniquement à des fins de communication avec vous et ne seront en aucun cas partagées, vendues ou louées à des tiers sans votre consentement explicite.
        </p>
        <p>
          Nous prenons des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé, altération, divulgation ou destruction. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons donc garantir une sécurité absolue de vos informations.
        </p>
        

        <h4>Protection des Informations</h4>
        <p>
          Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de vos informations personnelles.
        </p>

        <h4>Divulgation à des tiers</h4>
        <p>
          Nous ne vendons, n'échangeons et ne transférons pas vos informations personnelles identifiables à des tiers.
        </p>

        <h4>Consentement</h4>
        <p>
          En utilisant notre site, vous consentez à notre politique de confidentialité.
        </p>
        <p>
          En nous fournissant vos informations personnelles, vous consentez à notre collecte, notre utilisation et notre stockage conformément à cette Politique de Confidentialité.
        </p>

        <h4>Modifications de la Politique de Confidentialité</h4>
        <p>
          Si nous décidons de changer notre politique de confidentialité, nous posterons ces changements sur cette page.
        </p>

        <h4>Contact</h4>
        <p>
          Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter en utilisant les informations ci-dessous :
          <br/><br/>
          Nom: Maïssa Mezhoud
          <br/>
          Email: contact@maissadev.com
        </p>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialite;
