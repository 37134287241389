import React, { useState } from 'react';
import "./Services.css";
import 'animate.css';


// import InfosContact from './InfosContact';

import icon from '../../../assets/icon/html.png';
import arrowIcon from '../../../assets/icon/arrow-right.png';

import logoWeb from '../../../assets/icon/web-service.png';
import logoRefonte from '../../../assets/icon/refont-service.png';
import logoMaintenance from '../../../assets/icon/maintenance-service.png';
import logoDev from '../../../assets/icon/code-service.png';

import iconShopify from '../../../assets/icon/shopify.png';
import iconWordpress from '../../../assets/icon/wordpress.png';
import iconPHP from '../../../assets/icon/php.png';
import iconHTML from '../../../assets/icon/html.png';
import iconJS from '../../../assets/icon/js.png';
import iconReact from '../../../assets/icon/react.webp';
import iconFigma from '../../../assets/icon/figma.png.webp';
import iconTS from '../../../assets/icon/ts.png';
import iconCSS from '../../../assets/icon/css.png.webp';


const Services = () => {

    const skills = [
        { icon: iconJS, id: 1, top: '19%', left: '18%', width: '90px', height: '90px' },
        { icon: iconWordpress, id: 4, top: '18%', left: '40%', width: '110px', height: '120px' },
        { icon: iconHTML, id: 2, top: '18%', left: '65%', width: '100px', height: '100px' },
        { icon: iconCSS, id: 7, top: '38%', left: '17%', width: '110px', height: '110px' },
        { icon: iconShopify, id: 8, top: '38%', left: '40%', width: '110px', height: '110px' },
        { icon: iconFigma, id: 5, top: '40%', left: '66%', width: '100px', height: '100px' },
        { icon: iconReact, id: 10, top: '61%', left: '17%', width: '120px', height: '120px' },
        { icon: iconPHP, id: 3, top: '60%', left: '42%', width: '110px', height: '110px' },
        { icon: iconTS, id: 6, top: '61%', left: '66%', width: '100px', height: '100px' },
    ];





    return (
        <div className='services-container'>


            <div className='all-services-container'>

                <h2 className='services-title'>Des services pour tous vos besoins</h2>


                <div className='services-center-container'>
                    <div className='services-center'>
                        <div className='service-infos'>
                            <img src={logoWeb} alt="Logo Création de site web" className='service-logo' />
                            <h3 className='service-infos-title'>Création de site web</h3>
                            <p>Création sur mesure de votre site en ligne&nbsp;: vitrines, e-commerce, portfolios, événements, intranets&nbsp;...</p>
                        </div>
                        <div className='service-infos'>
                            <img src={logoRefonte} alt="Logo Refonte de site web" className='service-logo' />
                            <h3 className='service-infos-title'>Refonte de site web</h3>
                            <p>Modernisation de votre site avec amélioration du design, des performances et ajout de nouvelles options.</p>
                        </div>
                        <div className='service-infos'>
                            <img src={logoMaintenance} alt="Logo Maintenance" className='service-logo' />
                            <h3 className='service-infos-title'>Maintenance</h3>
                            <p>Correction de bugs, mises à jour, sauvegardes, optimisation, transfert de domaine & d'hébergement&nbsp;...</p>
                        </div>
                        <div className='service-infos'>
                            <img src={logoDev} alt="Logo Développement spécifique" className='service-logo' />
                            <h3 className='service-infos-title'>Développement spécifique</h3>
                            <p>Création de fonctionnalités personnalisées pour des besoins uniques que les solutions standard ne peuvent pas couvrir.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='skills-services-container'>
                <div className="skills-box">
                    {skills.map((skill) => (
                        <img
                            key={skill.id}
                            src={skill.icon}
                            alt={`Icon for skill ${skill.id}`}
                            style={{
                                position: 'absolute',
                                top: skill.top,
                                left: skill.left,
                                width: skill.width,
                                height: skill.height,
                                objectFit: 'contain',  // Assure que les icônes sont contenues sans déformation
                            }}
                        />
                    ))}
                    {/* ... */}
                </div>

            </div>
        </div>
    );
};

export default Services;
