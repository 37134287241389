import React, { useState, useEffect } from 'react';
import "./Realisations.css";
import { Link } from 'react-router-dom';
import data from '../../../assets/data.json';

const Realisations = () => {
    const [selectedProjects, setSelectedProjects] = useState([]);

    useEffect(() => {
        try {
            const projectIDs = [12, 6, 9, 10];
            const selectedProjects = data.filter(project => projectIDs.includes(project.id));
            const sortedProjects = projectIDs.map(id => selectedProjects.find(project => project.id === id));

            setSelectedProjects(sortedProjects);
        } catch (error) {
            console.error('Erreur:', error);
        }
    }, []);

    return (
        <div className='realisations-container'>
            <h2 className='realisations-title'>Mes réalisations</h2>
            <div className='realisations-center'>
                <div className='realisations-projects-container'>
                    {selectedProjects.map(project => (
                        <Link to={`/projets/${project.id}`} key={project.id}>
                            <div className='realisation-presentation' key={project.id}>
                                <img src={project.image_1} className='realisation-project-presentation' alt={`Projet ${project.id}`} />
                            </div>
                        </Link>
                    ))}
                </div>
                <div className='realisations-button'>
                    <Link to="/projets">Découvrir mes autres réalisations</Link>
                </div>
            </div>
        </div>
    );
};

export default Realisations;
