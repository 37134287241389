import React from 'react';
import "./InfosContact.css";

const InfosContact = ({ icon, title, subtitle }) => {

    const iconStyle = title === "E-mail" ? { height: '14px' } : {};

    return (
        <div className="infosContact-container">
            <div className="infosContact-row">
                <div className="infosContact-icon"> 
                    <img src={icon} alt="Icon"  style={iconStyle} className='infosContact-icon-style'/>
                </div>
                <div className="infosContact-content">
                    <p className="infosContact-title">{title}</p>
                </div>
            </div>

            <div className="infosContact-row">
                <div className="infosContact-content"> 
                    <h3 className="infosContact-subtitle">{subtitle}</h3>
                </div>
            </div>
        </div>
    );
};

export default InfosContact;
