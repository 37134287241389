
import "./Banner2.css";

export const Banner2 = () => {

    return (
        <section className="banner2" id="home">
            <div className="banner2-infos" >
                <h1>Conception de Sites Web</h1>
                <h2>Webmaster & Développement</h2>
                <p>Spécialiste en création de sites web, maintenance, développement de fonctionnalités sur mesure et refonte de sites.</p>
                <a href="#contact" class="banner-button">Discutons de votre projet</a>
            </div>
            {/* <Container>
                <Row className="align-items-center">
                    <Col xs={12} className="align-items-center banner-col">
                        {/* <span className="tagline">Bienvenue sur mon Portfolio</span>        */}
            {/* <TrackVisibility>
                            {({ isVisible}) =>
                                <div className={isVisible ? "animate__animated animate__pulse" : ""}> */}
            {/* </div>}
                        </TrackVisibility> */}
            {/* <div className="banner-content">
                        <TrackVisibility once>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeInDown": ""}>
                <h1> Conception de Sites Web</h1>
              
                </div>}
            </TrackVisibility>

            <TrackVisibility once>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeInUp": ""}>
                <h2 > De la vitrine à l'e-commerce, concrétisez vos projets en ligne</h2>
                <a href="#connect" class="bannerContact bannerContactMoi">Contactez-moi</a>
              
                </div>}
            </TrackVisibility> */}



            {/* <p class="bannerText">
                        Bienvenue ! Ingénieure informatique spécialisée dans le développement web, j'aide les clients à atteindre leurs objectifs. Si vous cherchez quelqu'un pour votre projet,
                         n'hésitez pas à me contacter pour discuter de vos besoins. </p> */}
            {/* <button href="#contact" >Let's connect <ArrowRightCircle size={25} /></button>    */}
            {/* onClick={() => setActiveLink('contact')} */}


        </section>
    )
}