import React, { useState, useEffect } from 'react';
import "./AvisHome.css";
import data from '../../../assets/data.json'
import { Link } from 'react-router-dom';

import starIcon from '../../../assets/icon/star.png';
import arrowIcon from '../../../assets/icon/arrow-right.png';

const AvisHome = () => {
    const [avisList, setAvisList] = useState([]);
    const [currentAvisIndex, setCurrentAvisIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [opacity, setOpacity] = useState(1);


    // useEffect(() => {
    //     fetch('http://api-maissadev.xita4709.odns.fr/avis-resume')
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log(data); // Vérifiez les données renvoyées par l'API
    //             const modifiedData = data.map(item => ({
    //                 stars: item.note_avis,
    //                 avis: item.mini_avis,
    //                 client: item.adresse_site.replace(/^(?:https?:\/\/)?/, '') // Enlève le http:// ou https:// au début
    //             }));
    //             setAvisList(modifiedData);
    //         })
    //         .catch(error => console.error('Erreur lors de la récupération des avis:', error));
    // }, []);

    useEffect(() => {
        // Filtrer les avis avec mini_avis différent de null
        const modifiedData = data
            .filter(item => item.mini_avis !== null) // Filtrer les éléments avec mini_avis différent de null
            .map(item => ({
                stars: item.note_avis,
                avis: item.mini_avis,
                client: item.nom_avis
            }));
        setAvisList(modifiedData);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setOpacity(0);
            setTimeout(() => {
                setCurrentAvisIndex(prevIndex => (prevIndex + 1) % avisList.length);
                setOpacity(1);
            }, 1100); // Attendre 2 secondes avant de changer d'avis et remettre l'opacité à 1
        }, 7000);

        return () => clearInterval(interval);
    }, [avisList]);

    if (avisList.length === 0) {
        return <div>Loading...</div>;
    }

    const { stars, avis, client } = avisList[currentAvisIndex];

    const starComponents = [];
    for (let i = 0; i < stars; i++) {
        starComponents.push(<img key={i} src={starIcon} alt="Icon" className='avis-home-icon-style' />);
    }

    return (
        <div className="avis-home-container">
            <div className={`avis-home-infos ${isVisible ? 'fade' : ''}`} style={{ opacity }}>
                {isVisible && (
                    <>
                        {/* <div className='stars-avis-home-container'>
                            {starComponents}
                        </div> */}
                        <div className='avis-home'>
                            <p>{avis}</p>
                        </div>
                        <div className='client-avis-home'>
                            <p>{client}</p>
                        </div>


                    </>
                )}
            </div>
            <div className="vertical-line-container">
            <div className="vertical-line"></div>
            </div>
            <div className='all-avis-home'>
                <Link to="/avis" className='all-avis-link'>Voir les avis clients <img src={arrowIcon} /></Link>
            </div>
        </div>
    );
};

export default AvisHome;
