import React from 'react';
import './PagesLegales.css';
import { Helmet } from 'react-helmet';

const CGV = () => {
  return (
    <div className="pages-legales-container">
       <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h2 className='pages-legales-title'>Conditions Générales de Vente (CGV)</h2>

      <div className="pages-legales-content">
        <h4>1. Objet</h4>
        <p>
          Les présentes conditions générales de vente (CGV) ont pour objet de définir les modalités et conditions dans lesquelles Maissa Dev propose à la vente ses produits et services.
        </p>

        <h4>2. Champ d'application</h4>
        <p>
          Les présentes CGV s'appliquent à toutes les ventes de produits et services effectuées par Maissa Dev via son site web.
        </p>

        <h4>3. Prix</h4>
        <p>
          Les prix des produits et services sont indiqués en euros, toutes taxes comprises (TTC).
        </p>

        <h4>4. Paiement</h4>
        <p>
          Le paiement des produits et services peut être effectué en ligne via les moyens de paiement acceptés sur le site web de Maissa Dev.
        </p>

        <h4>5. Droit de rétractation</h4>
        <p>
          Conformément à la législation en vigueur, le client dispose d'un délai de rétractation de 14 jours à compter de la réception des produits pour exercer son droit de rétractation.
        </p>

        <h4>6. Responsabilité</h4>
        <p>
          Maissa Dev décline toute responsabilité en cas de dommages causés par l'utilisation des produits ou services vendus sur son site web.
        </p>

        <h4>7. Litiges</h4>
        <p>
          En cas de litige, les parties s'efforceront de trouver une solution amiable. À défaut, le litige sera soumis aux tribunaux compétents français.
        </p>

        <h4>8. Modification des CGV</h4>
        <p>
          Maissa Dev se réserve le droit de modifier les présentes CGV à tout moment. Les modifications seront applicables dès leur publication sur le site web.
        </p>

        <h4>9. Contact</h4>
        <p>
          Pour toute question concernant les CGV, vous pouvez contacter Maissa Dev via les informations de contact disponibles sur le site web.
        </p>
      </div>
    </div>
  );
};

export default CGV;
