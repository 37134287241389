import React from 'react';
import './Error.css'; 
import maintenance from '../../assets/img/maintenance-website.png';

const Error = () => {
  return (
    <div className="error-container">
      <img src={maintenance} alt="Error" className="error-image" />
      <p className="error-text">La page que vous recherchez n'existe pas.</p>
    </div>
  );
};

export default Error;
