import React from 'react';
import "./Footer.css";
import { Link } from 'react-router-dom';

import iconPhone from '../../../assets/icon/phone.png';
import iconEmail from '../../../assets/icon/email.png';
import iconSite from '../../../assets/icon/web.png';
import iconSiren from '../../../assets/icon/settings.png';

import iconMarker from '../../../assets/icon/marker.png';

import logoMaissaDev from '../../../assets/icon/favicon.ico';

const Footer = () => {
    return (
        <footer >
            <div className="footer-columns">

                <div className="footer-column logo-footer-column">
                    <img src={logoMaissaDev} alt="Icon" />
                </div>
                <div className="footer-column">
                    <h3> &nbsp; </h3>
                    {/* Pages légales */}
                    <ul>
                        <li><Link to="/mentions-legales">Mentions Légales</Link></li>
                        <li><Link to="/politique-confidentialite">Politique de confidentialité</Link></li>
                        <li><Link to="/conditions-generales-ventes">Conditions Generales de Ventes (CGV)</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>À propos</h3>
                    <ul>
                        {/* <li><a href="#">Compétences</a></li> */}
                        <li><Link to="/">Accueil</Link></li>
                        {/* <li><Link to="/projets">Services</Link></li> */}
                        <li><Link to="/projets">Réalisations</Link></li>
                        <li><Link to="/avis">Avis</Link></li>
                    </ul>
                </div>
                {/* <div className="footer-column">
                <h3>Services</h3>
                <ul>
                    <li>Création de site web</li>
                    <li>Refonte de site web</li>
                    <li>Maintenance</li>
                    <li>Développement spécifiques</li>
                </ul>
            </div> */}
                <div className="footer-column">
                    <h3>Contact</h3>
                    <ul>
                        {/* <img src={iconPhone} alt="Icon" className='footer-icon-style'/>
                <img src={iconEmail} alt="Icon" className='footer-icon-style'/>
                <img src={iconSiren} alt="Icon" className='footer-icon-style'/>  */}
                        <li className='infos-footer-column'> contact@maissadev.com</li>
                        <li className='infos-footer-column'> +33 (0) 7 45 31 65 41</li>
                        {/* <li className='infos-footer-column'><img src={iconSite} alt="Icon" className='footer-icon-style'/> www.maissadev.com</li> */}
                        <li className='infos-footer-column'>Siren : 979 912 029</li>
                        {/* <li className='infos-footer-column'><img src={iconMarker} alt="Icon" className='footer-icon-style'/> 15 rue de belfort, 92110, Clichy</li> */}
                    </ul>
                </div>
            </div>

            <div className='designed-by-footer'>

                Copyright 2023-2024 © Designed & Developed by <a href="https://www.maissadev.com" target="_blank">Maissa Dev</a>

            </div>
        </footer>
    );
};

export default Footer;
