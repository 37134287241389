import React from 'react';
import './Avis.css'; // Import du fichier CSS
import starIcon from '../../assets/icon/star.png';
import { Link } from 'react-router-dom'; 

const Avis = ({ projet, image, background, showProjectLink}) => {

  const starComponents = [];
  for (let i = 0; i < projet.note_avis; i++) {
    starComponents.push(<img key={i} src={starIcon} alt="star-avis" className='avis-home-icon-style' />);
  }

  return (
    <div className={` single-focus-avis-container ${background === 'light' ? 'light-background' : 'dark-background'}`}>
      
      <div className='single-focus-avis'>
      
       <div className='single-focus-avis-image'>
        <img src={image} alt="Image de l'avis" /> 
        {/* <p className='single-focus-avis-link'>{projet.nom_avis}</p> */}
        {/* {showProjectLink && (
            <Link to={`/projets/${projet.id}`} className="single-focus-avis-link-details">Voir le projet</Link>
          )} */}
      </div>

      <div>
        <div className='stars-avis-projets-container'>
        <div className='avis-title'> {projet.nom_site} </div>
        {/* <div className='avis-nom-site'> {projet.title}</div> */}
          {/* <div> {starComponents} </div> */}
          <div className='text'>{projet.avis}</div>
        </div>
      </div>
      </div>

      

    </div>
  );
};

export default Avis;
